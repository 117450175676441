import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  // data.markdownRemark holds our post data
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout
      head={{
        title: `${frontmatter.title}`,
        meta: {
          banner: frontmatter.banner,
        },
      }}
    >
      <div className="blogpost-wrap">
        <div
          id="hero-section"
          className="bg-cover"
          style={{ background: `url(${frontmatter.banner})` }}
        >
          <div className="container py">
            <div className="slim-content">
              <h1>{frontmatter.title}</h1>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="blog-post-container">
            <div className="blog-post">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className="text-center share pt-5">
                <h3 className="mb-4">Share with Social Media</h3>
                <h3>
                  <LinkedinShareButton
                    className="d-inline mx-2"
                    url={`https://www.progrowthadvisors.com/${
                      frontmatter.cmsUserSlug
                    }`}
                  >
                    <i className="fa fa-linkedin-square cursor-pointer" />
                  </LinkedinShareButton>

                  <FacebookShareButton
                    className="d-inline mx-2"
                    url={`https://www.progrowthadvisors.com/${
                      frontmatter.cmsUserSlug
                    }`}
                  >
                    <i className="fa fa-facebook-square cursor-pointer" />
                  </FacebookShareButton>

                  <TwitterShareButton
                    className="d-inline mx-2"
                    url={`https://www.progrowthadvisors.com/${
                      frontmatter.cmsUserSlug
                    }`}
                  >
                    <i className="fa fa-twitter-square cursor-pointer" />
                  </TwitterShareButton>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($pathUrl: String!) {
    markdownRemark(frontmatter: { cmsUserSlug: { eq: $pathUrl } }) {
      html
      frontmatter {
        title
        author
        banner
        shortdesc
        updatedDate
        cmsUserSlug
        date(formatString: "MMMM DD, YYYY")
        categories
      }
    }
  }
`
